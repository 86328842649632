.a-logo {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-right: 7%;
  white-space: nowrap;

  a {
    text-decoration: none;
    &:after { display: none; }
  }

  &__img {
    display: inline-block;
    vertical-align: middle;
    width: 150px;
    height: auto;
    margin: 0;
  }

  &__text {
    margin-left: 13px;
    display: inline-block;
    vertical-align: middle;
    line-height: 1.2;
    text-align: left;
  }

  &__title,
  &__tagline {
    display: block;
    color: $logo-color;
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
  }

  &__tagline {
    font-size: 12px;
  }
}
