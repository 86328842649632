.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //transition: all 0.6s ease-out;

  &.hide {
    opacity: 0;
  }
}

.show .background-image {
  transition: all 0.6s ease-out;
}

.bg-img {
  position: absolute;
  overflow: hidden;


  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0;
    //transition: opacity 0.9s 0.9s, transform 2s;

    &.show {
      opacity: 1;
    }
  }

  &--full {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &--forth {
    width: 50%;
    height: 50%;
  }

  &--half-x {
    width: 50%;
    height: 100%;
  }

  &--half-y {
    width: 100%;
    height: 50%;
  }

  &--top-left {
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
  }

  &--top-right {
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
  }

  &--bottom-left {
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
  }

  &--bottom-right {
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
  }

}


@include below($mobile) {
  .template-2 .bg-img--forth.bg-img--top-left {
    width: 100%;
    height: 50%;
    top: 0;
    left: 0;
  }
  .template-2 .bg-img--forth.bg-img--bottom-right {
    width: 100%;
    height: 50%;
    top: 50%;
    left: 0;
  }
}
