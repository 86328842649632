// ==========================================================================
// Breakpoints
// ==========================================================================


$tiny:                       480px;
$xsmall:                     600px;
$small:                      768px;
$medium:                     960px;
$regular:                    1024px;
$large:                      1280px;
$xlarge:                     1440px;
$xxlarge:                    1550px;
$huge:                       1650px;
$biggusdickus:               1920px;


$mobile: $small + 1;


// ==========================================================================
// Paths
// ==========================================================================

$asset-base-path: '../';
$asset-images-path: $asset-base-path + 'img/';
$asset-fonts-path: $asset-base-path + 'fonts/';





// ==========================================================================
// Colors
// ==========================================================================

$white:                      #fff;
$black:                      #000;
$grey:                       #3C3D3C;
$grey-dark:                  #494949;
$grey-light:                 #F0F0F0;
$yellow:                     #FFED00;
$yellow-light:               #F6F5EE;
$logo-color:                 #2F2F2E;



// ==========================================================================
// Fonts
// ==========================================================================

$sans:                       'FlandersArtSans', sans-serif;
$serif:                      'FlandersArtSerif', serif;
$default-font-family:        $sans;
$title-font-family:          $serif;

$monospace-font-family:      monospace;
$fontSmooth:                 true;

$text-color:                 $grey;
$anchor-text-color:          $grey;
$anchor-text-hover-color:    $grey;


$default-font-size:          16px;
$default-line-height:        1.2;
$small-font-siz:             75%;

$nav-list-style:             none;






// ==========================================================================
// Animations
// ==========================================================================

$base-transition:            all .3s ease-out;



// ==========================================================================
// Forms
// ==========================================================================

$form-element-background-color: transparent;
$form-element-min-height: 1.5em;

// ==========================================================================
// Tables
// ==========================================================================

$table-border-collapse: collapse;
$table-border-spacing: 0;
$textarea-resize: vertical;

// ==========================================================================
// Selection
// ==========================================================================
$selection-background-color: $md-blue-300;
$selection-color: #fff;
$selection-text-shadow: none;



$media-element-vertical-align: middle;
