.a-progress-bar {
  display: block;
  height: 3px;
  width: 100%;

  &__pos {
    display: block;
    height: 100%;
    background: $yellow;
  }
}
