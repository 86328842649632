.a-counter {
  @extend %ff-serif;
  @extend %fw-medium;
  opacity: 0;
  transition: all 0.3s;

  &.show {
    opacity: 1;
  }

  font-size: rem(16);
  display: flex;
  align-items: center;

  &__divider {
    position: relative;
    width: em(20, 16);
    height: em(30, 16);

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 2px;
      height: 100%;
      background: $yellow;
      left: 50%;
      transform: rotate(20deg) translateX(-50%);
      z-index: 1;
    }

    svg {
      fill: $grey-dark;
      position: absolute;
      display: block;
      bottom: 50%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 0;
    }
  }
}
