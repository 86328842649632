.rellax_box {
  position: absolute;
  z-index: 999;
  transition: all 0.2s ease-out;

  &.rellax_box_lines {
    width: 100px;
    height: 200px;
    background: url('../img/parallax/lines.svg') center center no-repeat;
  }

  &.rellax_box_triangle {
    width: 100px;
    height: 200px;
    background: url('../img/parallax/triangles.svg') center center no-repeat;
  }

  &.rellax_box_zigzag {
    width: 100px;
    height: 200px;
    background: url('../img/parallax/zigzag.svg') center center no-repeat;
  }
}
