@function flexText($minFontSize: 16, $maxFontSize: 80, $minViewPort: 300, $maxViewPort: 1600) {
  $minFontSizeUnit: if(unit($minFontSize) is '', 'px' , unit($minFontSize));
  $minViewPortUnit: if(unit($minViewPort) is '', 'px' , unit($minViewPort));

  $minFontSize: strip-unit($minFontSize);
  $maxFontSize: strip-unit($maxFontSize);
  $minViewPort: strip-unit($minViewPort);
  $maxViewPort: strip-unit($maxViewPort);

  $minFontSizeWithUnit: $minFontSize + $minFontSizeUnit;
  $minViewPortWithUnit: $minViewPort + $minViewPortUnit;

  // We need to return this calc function: calc(30px + (120 - 30) * ((100vw - 300px) / (1600 - 300)))
  @return calc(#{$minFontSizeWithUnit} + (#{$maxFontSize} - #{$minFontSize}) * ((100vw - #{$minViewPortWithUnit}) / (#{$maxViewPort} - #{$minViewPort})));
}
