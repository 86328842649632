.o-section {
  position: relative;

  &__content {
    position: relative;
    z-index: 1;
    @include above($mobile) {
      @include centerY();
    }
  }
}
