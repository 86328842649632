// ==========================================================================
// Fonts
// ==========================================================================
// Font-weights
// Thin (Hairline): 100
// Extra Light (Ultra Light): 200
// Light: 300
// Normal: 400
// Medium: 500
// Semi Bold (Demi Bold): 600
// Bold: 700
// Extra Bold (Ultra Bold): 800
// Black (Heavy): 900
// ==========================================================================

$base_font_path: if(
  variable-exists(base-font-path),
  $base_font_path,
  '../fonts/'
);

$fonts: (
  'FlandersArtSans': (
    (300, normal, "FlandersArtSans/FlandersArtSans-Light"),
    (400, normal, "FlandersArtSans/FlandersArtSans-Regular"),
    (500, normal, "FlandersArtSans/FlandersArtSans-Medium"),
    (700, italic, "FlandersArtSans/FlandersArtSans-Bold")
  ),
  'FlandersArtSerif': (
    (300, normal, "FlandersArtSerif/FlandersArtSerif-Light"),
    (400, normal, "FlandersArtSerif/FlandersArtSerif-Regular"),
    (500, normal, "FlandersArtSerif/FlandersArtSerif-Medium"),
    (700, italic, "FlandersArtSerif/FlandersArtSerif-Bold")
  )
);

@if (variable-exists(fonts)) {
  @each $name, $properties in $fonts {
    @each $weight, $style, $path in $properties {
      @font-face {
        font-family: $name;
        font-style: $style;
        font-weight: $weight;
        src: url($base_font_path + $path + '.woff2') format('woff2'), url($base_font_path + $path + '.woff') format('woff');
      }
    }
  }
}
