$divider-height: 100px;
$border-width: 4px;
$border-color: $yellow;

.a-template-divider {
  display: none;
  width: 100%;
  height: $divider-height;
  background: $white;
  border-width: 0 ($border-width - 1px);
  border-style: solid;
  border-color: $border-color;
  position: absolute;
  z-index: 1;
  top: -$divider-height;

  @include above($mobile) {
    border-width: 0 $border-width;
    display: block;
  }

  .o-project:first-child & {
    display: none;
  }

  .template-3 &,
  .template-4 & {
    &:before {
      content: '';
      display: block;
      width: $border-width;
      height: 100%;
      background-color: $border-color;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }


}
