.for-screen-reader {
  display: block;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(1px);
  visibility: hidden;
  white-space: nowrap;
  position: absolute;
}


.nowrap {
  white-space: nowrap;
}


.show-on-mobile {
  display: block;

  @include above($mobile) {
    display: none;
  }
}

.hide-on-mobile {
  display: none;

  @include above($mobile) {
    display: block;
  }
}
