@mixin centerXY() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin centerY() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin centerX() {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
