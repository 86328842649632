$template: '.template-2';

#{$template}__content {
  @include above($mobile) {
    height: rem(800);
  }
}


#{$template}__section-a,
#{$template}__section-b {
  position: relative;
  @include above($mobile) {
    height: 50%;
  }

}


#{$template}__section-a {

  @include above($mobile) {
    margin-left: 50%;
    padding-bottom: rem(100);
  }
}

#{$template}__section-a #{$template}__inner {
  padding: rem(230) rem(25) rem(90);

  @include above($mobile) {
    padding: 0 rem(10);
  }

  @include above($large) {
    padding: 0 rem(60);
  }

}


#{$template} .a-default-title{
  @include above($mobile) {
    margin-top: rem(-20);
  }
}


#{$template}__text {
  @include above($mobile) {
    margin-left: rem(50);
    max-width: rem(400);
  }

  @include above($large) {
    margin-left: rem(155);
  }
}




#{$template}__section-b {
  // padding: rem(25);

  @include above($mobile) {
    padding: rem(40) rem(25) rem(40) rem(25);
  }

  .a-fact__result {
    @include above($mobile) {
      font-size: rem(100);
      padding-bottom: .1em;
    }
  }

  .template-2__inner {
    padding: rem(230) rem(25) rem(90);

    @include above($mobile) {
      max-width: rem(500);
      margin: auto;
      padding: 0;
    }
  }

}


#{$template} {
  .background .bg-img.bg-img--top-left:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #FFED00;
    transition: all 0.6s 0.3s ease-out;
  }

  &.show .background .bg-img.bg-img--top-left:after {
    height: 0;
    top: 100%;
  }


  .background .bg-img.bg-img--bottom-right:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #F6F5EE;
    transition: all 0.4s 0.3s ease-out;
  }

  &.show .background .bg-img.bg-img--bottom-right:after {
    height: 0;
  }
}
