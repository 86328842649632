$template: '.template-1';

#{$template} {

  .background {

    @include below($mobile) {
      position: relative;
      width: 100%;
      height: 730px;
    }

  }
}

#{$template}__content {
  background-color: $yellow-light;

  @include above($mobile) {
    background-color: transparent;
  }
}

#{$template}__section-a #{$template}__inner {
  padding: em(25);

  @include above($mobile) {
    padding: rem(140) rem(20);
  }

  @include above($large) {
    padding: rem(180) rem(60);
  }

}


#{$template}__text {
  @include above($mobile) {
    margin-left: rem(45);
    max-width: rem(400);
  }

  @include above($large) {
    margin-left: rem(190);
  }
}



#{$template}__section-b {
  padding: rem(25);

  // .o-facts {
  //   display: flex;
  //   flex-wrap: nowrap;
  //   justify-content: space-between;
  //   padding: 0 0 rem(20) 0;
  //
  // }
  // .a-fact {
  //   width: 45%;
  // }

  @include above($mobile) {

    #{$template}__inner {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      left: rem(0);
      padding: 0;
    }

    .o-facts {
      position: absolute;
      top: 50%;
    }

    .a-fact {
      width: rem(230px);

      &__result {
        padding-bottom: .1em;
      }
    }

  }
}



#{$template} {
  .background .bg-img.bg-img--full {
    transform: scale(0.96);
    opacity: 0;
    transition: all 0.9s ease-out;
  }

  &.show .background .bg-img.bg-img--full {
    transform: scale(1);
    opacity: 1;
  }

}
