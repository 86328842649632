.o-footer {

  &__top {
    $fs: 20;
    text-align: center;
    font-size: rem($fs);
    line-height: em(28, $fs);
    padding: rem(30) 0;
    background-color: $yellow-light;
    position: relative;

    .o-footer__inner {
      position: relative;
      z-index: 1;
      width: 400px;
      max-width: 90%;
      margin: auto;

      @include above($xsmall) {
        width: auto;

      }
    }

    br {
      display: none;
      @include above($xsmall) {
        display: block;
      }
    }
    a {
      border-bottom: 1px solid $grey;
      &:after { display: none; }
    }

    &:after {
      content: '';
      display: block;
      width: rem(80);
      height: rem(52);
      background: url('../img/ui/icn-footer-statistiek.svg');
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      bottom: rem(25);
      left: rem(25);
      z-index: 0;

      @include above($xsmall) {
        bottom: rem(10);
        left: 50%;
        margin-left: rem(-250);
      }
    }
  }

  &__bottom {
    $fs: 20;
    text-align: left;
    font-size: rem($fs);
    line-height: em(28, $fs);
    padding: rem(30) rem(40);
    text-align: center;
    border-top: 1px solid #cbd2da;
    color: #333;

    @include above($mobile) {
      text-align: left;
      display: flex;
    }
  }
}

.o-footer__logo {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-right: 7%;
  white-space: nowrap;
  margin-bottom: rem(20);

  @include above($mobile) {
    margin-bottom: 0;
  }

  .a-logo:before {
    background-color: #cbd2da;
  }
}

.o-footer__site-info {
  display: inline-block;
  vertical-align: top;
  margin-right: 4%;
  flex-grow: 1;
  flex-shrink: 1;

  &__title {
    font-size: 14px;
    line-height: 1.25;
    font-weight: 400;

    @include above($mobile) {
      font-size: 19px;
      line-height: 1.25;
      font-weight: 500;
    }
  }

  &__publisher {
    font-size: 14px;
    line-height: 1.2;
    overflow: hidden;
    color: #3b3b3c;
    margin-top: 5px;

    @include above($mobile) {
      margin-top: 0;
    }

    a {
      text-decoration: underline;
      outline: 0;
      color: #06c;

      // &:after { display: none; }
      &:hover { text-decoration: none; }
    }
  }
}

.o-footer__navigation {
  margin-top: 10px;
  position: relative;
  overflow: hidden;
  display: inline-block;

  @include above($mobile) {
    margin-top: 15px;
  }
}

.o-footer__menu-item {
  float: left;
  position: relative;
  font-size: 14px;
  line-height: 1.5;
  text-transform: uppercase;
  font-weight: 500;

  &:before {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    top: 5px;
    bottom: 5px;
    width: 1px;
    background-color: #e8ebee;
  }

  a {
    color: #333332;
    display: block;
    padding: 0 5px;

    @include above($mobile) {
      padding: 0 10px;
    }

    // &:after { display: none; }
    &:hover { color: #06c; }
  }

  &:first-child {
    &:before { display: none; }
    a { padding-left: 0; }
  }
}
