@mixin p($font-size: 16, $line-height: 22, $margin: 22, $font-weight: 400){
  font-size: rem($font-size);
  font-weight: $font-weight;
  line-height: em($line-height, $font-size);
  margin: em($margin, $font-size) 0;
}



.a-default-title {
  $fs: 28;
  @extend %fw-bold;

  position: relative;
  font-size: rem($fs);
  line-height: em(38,$fs);
  color: $grey-dark;

  .icn {
    @include size(em(40, $fs));
    position: absolute;
    top: em(-40, $fs);
  }

  @include above($mobile) {
    $fs: 31;
    font-size: rem($fs);
    line-height: em(38,$fs);
    max-width: em(500, 31);
    padding-left: em(50, $fs);
    letter-spacing: .05em;

    .icn {
      top: -.1em;
      left: 0;
    }
  }
}

.a-floating-title {
  @extend %fw-medium;
  $fs: 24px;

  position: relative;
  text-align: center;
  z-index: 9;
  text-align: center;
  font-size: rem($fs);
  line-height: 1;
  color: $grey-dark;
  padding: rem(40) rem(25) 0;

  .template-4__inner & {
    padding-left: 0;
    padding-right: 0;
  }

  .a-title__inner {
    display: inline-block;
    background: $yellow;
    text-align: left;
    position: relative;
    padding: em(20, $fs) em(20, $fs) em(20, $fs) em(65, $fs);
  }

  .icn {
    @include size(em(30, $fs));
    position: absolute;
    top: em(18, $fs);
    left: em(20, $fs);
    background-size: contain;
  }

  @include above(321px) {
    // $fs: 28;
    // font-size: rem($fs);
    //
    // .icn {
    //   @include size(em(35, $fs));
    //   top: em(20, $fs);
    //   left: em(20, $fs);
    // }
  }

  @include above($mobile) {
    $fs: 31;
    position: absolute;
    top: em(60, $fs);
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    padding: 0;
    margin-top: 25px;
    opacity: 0;
    transition: all 0.6s ease-out;

    .a-title__inner {
      padding: em(26, $fs) em(25, $fs) em(26, $fs) em(90, $fs);
    }

    .a-title__inner * {
      opacity: 0;
      transition: all 0.5s ease-out;
    }

    .icn {
      @include size(em(50, $fs));
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: em(20, $fs);
    }
  }
}


.a-subtitle {
  $fs: 18;
  @extend %fw-medium;

  max-width: rem(300);
  font-size: rem($fs);
  line-height: em(29,$fs);
  margin: em(30, $fs) 0 em(20, $fs);
}


.a-copy {
  $fs: 16;
  @extend %fw-medium;

  font-size: rem($fs);
  line-height: em(26,$fs);
  margin: em(30, $fs) 0;

  p + p { margin-top: rem(40); }

  strong {
    @extend %fw-bold;
  }
}


.show .a-floating-title {
  opacity: 1;
  margin-top: 0;

}

.show .a-floating-title .a-title__inner *{
  opacity: 1;
}
