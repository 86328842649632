.o-project {
  position: relative;


  &:first-child {
    margin-top: auto;
  }

  @include above($mobile) {
    margin-top: $divider-height;
  }

  &__content {
    position: relative;
    z-index: 1;

    @include above($mobile) {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;
    }
  }

  &__section {
    @include above($mobile) {
      position: relative;
      width: 50%;
      // outline: 1px dashed rgba($black,.3);

      // &:before {
      //   content: '';
      //   display: block;
      //   position: absolute;
      //   width: 100%;
      //   height: 50%;
      //   border-bottom: 1px dashed rgba($black,.3);
      // }
    }
  }

  &__section__inner {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;
    @include above($mobile) {
      // position: absolute;
      // padding: 0 0 0 em(60, 16);
    }
  }

}
