.btn {
  $fs: 16;

  @extend %fw-bold;
  @include when-is(button) {
    appearance: none;
    border: 0;
    cursor: pointer;
  }

  display: inline-block;
  font-size: rem($fs);
  text-decoration: none;
  line-height: 1.3;
  padding: em(12, $fs);
  min-width: 190px;
  text-align: center;
  color: $grey-dark;
  transition: color .3s ease, background-color .3s ease;
  text-transform: uppercase;

  &.-default {
    background-color: $grey-dark;
    color: $white;

    &:hover {
      background-color: $yellow;
      color: $grey-dark
    }
  }
  // &:after {
  //   display: none;
  // }
}
