.o-section--intro {
  opacity: 0;
  transition: all 0.3s;
  padding-bottom: 30vh;
  @include above($mobile) {
    @include section-size;
  }

  &.show {
    opacity: 1;

    @include below($small) {
      .bg-img__inner {
        opacity: .6;
      }
    }
  }

  .bg-img__inner {
    background-position: center;

    @include below($small) {
      background-position: right bottom;
    }
  }
}


.o-section__content--intro {
  @include above($mobile) {
    width: 50%;
    left: 25%;
    margin-top: 5vh;
  }

}

.o-section--intro {

  .o-section__content--intro {
    //margin-top: -50px;
    opacity: 0;
    transition: all 0.6s 0.6s ease-out;
  }

  &.show .o-section__content--intro {
    //margin-top: 0;
    opacity: 1;
  }
}





.a-intro-title {
  $fs: 32;
  $ti: 1.5em;

  font-size: rem(32);
  line-height: em(32, 32);
  padding: rem(40) 0 0 0;
  width: rem(325);
  margin: auto;

  span {
    display: inline-block;
    //text-indent: -$ti;

    background-color: $white;
    position: relative;
      padding: em(2) em(5);

      &:after {
        content: "";
        width: 100%;
        height: 100%;
        background: $white;
        position: absolute;
        left: 0;
        right: 0;
        z-index: -1;
      }

    &:last-child {
      margin-left: 1.05em;
    }
  }

  @include below($mobile) {
    padding-top: 25vh;
  }

  @include above($mobile) {
    $fs: 66;
    $ti: 1.6em;

    font-size: flexText($minFontSize: 30, $maxFontSize: 66, $minViewPort: 300, $maxViewPort: 1600);
    line-height: em(70,$fs);
    width: auto;
    padding-left: $ti;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: -270px;

    span {
      font-size: em(69,$fs);

      text-transform: uppercase;
      text-indent: 0;
      position: relative;

      display: inline-block;
      background-color: $white;
      padding: em(2) em(5);

      &:after {
        content: "";
        width: 100%;
        height: 100%;
        background: $white;
        position: absolute;
        left: 0;
        right: 0;
        z-index: -1;
      }

      &:last-child {
        margin-left: 1.02em;
      }
    }
  }
}



.a-intro-description {
  margin: auto;
  padding-left: 3em;
  max-width: rem(325);

  p {
    $fs: 16;
    font-size: rem($fs);
    line-height: em(26, $fs);
    margin: em(22, $fs) 0;
  }

  @include above($mobile) {

    margin: 0;
    margin-left: -20%;
    padding-left: 0;
    max-width: 60%;

    p {
      $fs: 18;
      font-size: rem($fs);
      line-height: em(29, $fs);
      margin: em(30, $fs) 0;
    }
  }

  @include above($medium) {
    max-width: rem(464);
  }

  @include above(1400px) {
    margin: 0;
    margin-left: 0;
  }


}


.a-intro-cta {
  $fs: 16;
  @extend %fw-medium;

  max-width: 190px;
  font-size: rem($fs);
  line-height: em(26, $fs);
  position: relative;

  & > span {
    display: block;
    line-height: 1.2em;
    font-size: rem(50);
    color: $black;
  }

  .icn {
    @include size(em(40, $fs));
    position: absolute;
    left: em(-34, $fs);
    top: -1px;
  }
}
