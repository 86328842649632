.a-subprojects-accordion {
  margin: rem(25) 0 0;
}


.a-subprojects-accordion__title {
  @extend %ff-serif;
  @extend %fw-medium;

  font-size: rem(20);
  padding: rem(25) rem(25) rem(25) rem(50);
  cursor: pointer;
  position: relative;

  &:before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 0 8px 16px;
    border-color: transparent transparent transparent $grey;
    margin-left: rem(-25)
  }

  &[aria-selected="true"] {
    background-color: $yellow-light;

    &:before {
      transform: rotate(90deg);
      border-color: transparent transparent transparent $yellow;
    }
  }


}

.a-subprojects-accordion__content {
  overflow: hidden;
  position: relative;

  &[aria-hidden="true"] {
    height: 0;
  }

  .a-copy {
    margin: rem(25) 0;
  }

  .a-fact__result {
    margin: rem(25) 0;
  }

  .btn {
    margin: rem(25) 0 rem(80);
  }
}

.a-subprojects-accordion__content__inner {
  padding: 0 rem(25);
  position: relative;
  z-index: 2;
}
