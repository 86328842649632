$template: '.template-4';

#{$template}__content {
  .a-subtitle {
    margin-top: 0;
  }

  .icn--large {
    background-size: contain;
    width: rem(120);
    height: rem(120);
    position: absolute;
    bottom: 60%;
    left: rem(-22);
    z-index: 0;
    opacity: .6;
    display: none;

    @include above($mobile) {
      display: block;
    }


  }

  @include above($mobile) {
    height: rem(800);
  }
}

#o-project--toeristische-cijfers #{$template}__content .icn--large {
  left: rem(10);
}

#{$template}__section-a,
#{$template}__section-b {
  z-index: 1;
  position: relative;
}

#{$template}__section-a {
  order: 2;
}

#{$template}__section-b {
  order: 1;
}


#{$template}__section-a #{$template}__inner {
  padding: rem(25) rem(25) rem(90);

  .a-floating-title {
    margin-bottom: rem(200);
  }

  @include above($mobile) {
    position: absolute;
    top: rem(140);
    left: rem(40);
    max-width: rem(320);
    padding: 0;

    .a-floating-title {
      margin-bottom: 0;
    }
  }
}


#{$template}__section-b #{$template}__inner {
  padding: rem(230) rem(25) rem(90);

  @include above($mobile) {
    position: absolute;
    top: 50%;
    left: rem(40);
    width: 50%;
    min-width: rem(290);
    padding: 0;
    height: auto;

    #o-project--toeristische-cijfers &,
    #o-project--resultaten-toeristische-consumentenkanalen & {
      top: auto;
      bottom: 0;
    }
  }
}


#{$template} {

  .background .bg-img.bg-img--top-left .bg-img__inner {
    //left: -50px;
    opacity: 0;
    width: calc(100% + 50px);
    height: calc(100% + 50px);
    transform: translateX(-50px);
    transition: all 0.6s ease-out;
  }

  &.show .background .bg-img.bg-img--top-left .bg-img__inner {
    transform: translateX(0);
    opacity: 1;
  }


  .background .bg-img.bg-img--top-right .bg-img__inner {
    transform: translateX(50px);
    opacity: 0;
    transition: all 0.6s ease-out;
  }

  &.show .background .bg-img.bg-img--top-right .bg-img__inner {
    transform: translateX(0);
    opacity: 1;
  }

}
