// ==========================================================================
// Extends and Helpers
// ==========================================================================

%borderbox { box-sizing: border-box; }

%left  { float: left; }
%right { float: right; }
%clear { @include clearfix(); }

%transition { transition: if(variable-exists(base-transition), $base-transition, 'all .3s ease');}


// Fonts
%ff-sans   { font-family: $sans; }
%ff-serif  { font-family: $serif; }
%fw-light  { font-weight: 300; }
%fw-normal { font-weight: 400; }
%fw-medium { font-weight: 500; }
%fw-bold   { font-weight: 700; }
