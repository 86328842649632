html, body { width: 100%; }
body {
  @extend %ff-sans;
  color: $text-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

b, strong {
  @extend %fw-medium;
}

a {
  text-decoration: none;
  position: relative;

  // &:after {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   height: 1px;
  //   background: $grey;
  //   bottom: 0;
  //   left: 50%;
  //   right: 50%;
  //   opacity: 0;
  //   transition: left .3s ease-out,
  //               right .3s ease-out,
  //               opacity .3s ease-out;
  // }
}

a, a:visited {
  color: $anchor-text-color;
}

a:active, a:hover {
  color: $anchor-text-hover-color;

  // &:after {
  //   left: 0;
  //   right: 0;
  //   opacity: 1;
  // }
}



mark {
  background-color: $yellow;
  padding: 0 .2em;
  margin: 0 -.17em;
}

#main-content {
  overflow: hidden;
  position: relative;
}

.constrain {
  max-width: $biggusdickus;
  margin: auto;
}


.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: white;
  transition: all 0.4s;
  opacity: 1;

  &.hide {
    height: 0;
    background: $yellow;

    .loader__inner {
      height: 0 !important;
    }
  }

  &.hidden {
    display: none;
  }


  .loader__inner {

    position: fixed;
    top: 50%;
    margin-top: -2px;
    left: 0;
    width: 0;
    height: 4px;
    background: $yellow;
    transition: height 0.4s ease-out, top 0.4s ease-out;

    &.show {
      height: 100%;
      top: 0;
    }

  }
}

// canvas {
//   display: none;
// }

// .is-visible canvas {
//   display: block;
// }