$icons: ( 'a-plus', 'arrow-down', 'breugel', 'bed-wit', 'bus', 'bus-2', 'bus-2-wit', 'camera', 'computer', 'congres', 'discobal', 'drukpers', 'europa', 'familie', 'familie-wit', 'fietser', 'wereldbol', 'grafiek', 'groenesleutel', 'handen', 'hoofdtelefoon', 'huis', 'info', 'jeugdherberg', 'keuken', 'koffer', 'kroon', 'kwaliteitstraject', 'laurier', 'park', 'poppy', 'rubens', 'strand', 'tank', 'theater', 'toegankelijkheidslabel', 'toerismeaward', 'vakantie', 'vliegtuig', 'vliegtuig-2', 'vliegtuig-2-wit', 'wifi', 'wifi-wit', 'www', 'zoeken', 'koffer-black', 'grafiek-wit');

.icn {
  @include size(rem(20))
  content: '';
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
}


@each $icon in $icons {
  .icn--#{$icon} {
    background-image: url('../img/icons/icn-#{$icon}.svg');

    @if($icon == 'kroon') {
      background-size: 40px auto;
    }

    @if($icon == 'groenesleutel') {
      background-size: auto 35px;
    }
  }
}
