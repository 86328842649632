$header_height: 83px;
$header_padding: 100px;

.o-header-placeholder {
  width: 100%;
  height: $header_height;
}

.o-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: $biggusdickus;
  width: 100%;
  height: $header_height;
  background: $grey-light;
  z-index: 9999;

  &__inner {
    position: relative;
    // width: calc(100% - (#{$header_padding} * 2));
    height: 100%;
    margin: auto;
  }

  &__logo,
  &__counter {
    @include centerY();
  }

  &__logo {
    left: 20px;

    @include above($mobile) {
      left: 5%;
    }

    @include above($large) {
      left: $header_padding;
    }
  }

  &__counter {
    right: 20px;

    @include above($mobile) {
      right: 5%;
    }

    @include above($large) {
      right: $header_padding;
    }
  }

  &__tagline {
    @include centerXY();
    display: none;
    font-size: rem(16);
    text-transform: uppercase;
    letter-spacing: .03em;
    font-weight: 500;
    text-align: center;

    margin-top: -20px;
    opacity: 0;
    transition: all 0.6s 0.8s;

    &.show {
      margin-top: 0;
      opacity: 1;
    }

    strong {
      @extend %fw-bold;
    }

    @include above($mobile) {
      display: block;
    }
  }

  &__progress {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
